@import "./css/bootstrap.min.css";
@import "css/jquery-ui.min.css";

@import "./css/animate.css";
// @import "./css/css-plugin-collections.css";
@import "./css/menuzord-megamenu.css";
@import "./css/menuzord-boxed.css";
@import "./css/style-main.css";
@import "./css/preloader.css";
@import "./css/custom-bootstrap-margin-padding.css";
@import "./css/responsive.css";
@import "./css/theme-skin-color-set1.css";
@import "./custom.scss";
@import "font-awesome";
