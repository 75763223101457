.quill-editing-wrapper-error.ql-toolbar,
.quill-editing-wrapper-error.ql-container {
  border: 1px solid red !important;
}

.ql-toolbar.ql-snow {
  border: none !important;
  background-color: rgba(0, 0, 0, 0.3) !important;
}

.ql-editor.ql-blank {
  padding: 12px !important;
}

.input-textarea-editor.hidden-toolbar .ql-editor.ql-blank {
  padding: 0px !important;
}

.ql-editor.ql-blank::before {
  font-style: normal !important;
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
  left: 0 !important;
}

.input-textarea-editor .ql-container.ql-snow {
  max-height: 700px;
  overflow-y: scroll;
  border: none !important;
  border-bottom: 2px solid #d2d2d2 !important;
}

.input-textarea-editor.has-error .ql-container.ql-snow {
  border-bottom: 1px solid red !important;
}

.input-textarea-editor.hidden-toolbar .ql-toolbar {
  display: none !important;
}
